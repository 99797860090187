


.slide-in {
    display: block !important;
}
.header  .one{
  display: none !important;
}
.header  .two{
  display: block;
}